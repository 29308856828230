import React from 'react';
import { ChatView } from './components/ChatView.tsx';

export interface FormResponses {
  working_title: string;
  sector_of_activity: string;
  tasks: {
    generated: TaskList;
    selected: TaskList;
    total_selected: number;
    total_generated: number;
  };
  feedbacks: {
    matching_personal_tasks: 0 | 1 | 2 | 3 | 4 | 5;
    task_list_completeness: 0 | 1 | 2 | 3 | 4 | 5;
    comments: string
  };
}

export interface badRequestResponses{
  working_title: string;
  sector_of_activity: string;
  reason: string;
}

export type TaskCategoryName = string;
export type TaskCategoryTasks = string[];

export interface TaskList {
  [category: TaskCategoryName]: TaskCategoryTasks;
}
  
const App: React.FC = () => {
  
  return (
    <div className="App">
      <ChatView />
    </div>
  );
};

export default App;
