import React from 'react';

interface UserReviewButtonsProps {
  scaleLabels: string[];
  onReview: (note: number) => void;
}

interface ReviewOption {
  label: string;
  value: number;
  color: string;
}


// export const ChatScaleReviewSection = ({ scaleLabels, onReview }: UserReviewButtonsProps) => {
//   const [note, setNote] = React.useState<number | undefined>(undefined);

//   const reviewOptions: ReviewOption[] = [
//     { label: scaleLabels[0], value: 1, color: "bg-blue-400" },
//     { label: scaleLabels[1], value: 2, color: "bg-blue-500" },
//     { label: scaleLabels[2], value: 3, color: "bg-blue-600" },
//     { label: scaleLabels[3], value: 4, color: "bg-blue-700" },
//     { label: scaleLabels[4], value: 5, color: "bg-blue-800" },
//   ];


//   // if (isSubmitted) {
//   //   return (
//   //     <div className="flex flex-col items-center justify-center w-full">
//   //       <p className="text-sm font-semibold text-teal-600">Merci pour votre retour !</p>
//   //       <p className="text-sm text-zinc-600">Votre avis nous aide à améliorer notre service.</p>
//   //     </div>
//   //   );
//   // }

//   return (
//     <div className="flex flex-col">
//       <div className="hidden sm:flex sm:space-x-1">
//         {reviewOptions.map((option: ReviewOption) => (
//           <div className={'flex flex-col items-center gap-1'} key={option.value}>
//             <p className={[
//               "font-bold",
//               note === option.value
//                 ? "text-blue-800"
//                 : "text-zinc-600"
//               // option.color.replace('bg', 'text'),
//             ].join(" ")}
//             >
//               {option.value}
//             </p>
//             <button
//               key={option.value}
//               type="button"
//               onClick={() => {
//                 if (note !== undefined) return;
//                 setNote(option.value);
//                 onReview(option.value);
//               }}
//               className={[
//               // option.color,
//                 "text-white px-2 py-1 h-full font-semibold text-xs rounded",
//                 note === option.value
//                   ? "bg-blue-800"
//                   : "bg-zinc-500 hover:bg-blue-600"
//                 // note === option.value && "ring ring-zinc-800"
//                 // : `ring-4 ${option.color.replace('bg', 'ring')}`,
//               ].join(" ")}
//             >
//               {option.label}
//             </button>
//           </div>
//         ))}
//       </div>

//       {/* {(note && isSubmitted === false) && (note === 1 || note === 5) && (
//         <div className="flex flex-col gap-2 mt-4">
//           {note === 1 && <p className="text-sm font-medium leading-tight text-red-600">Avez-vous des remarques ou suggestions ?</p>}
//           {note === 5 && <p className="text-sm font-medium leading-tight text-teal-600">Que pouvons nous potentiellement améliorer ?</p>}
          
//           <textarea
//             className="w-full p-2 text-sm border border-gray-300 rounded mt"
//             placeholder="Écrivez votre retour ici..."
//             onChange={(e) => setComment(e.target.value)}
//           />
//         </div>
//       )} */}

//       {/* Mobile layout */}
//       <div className="sm:hidden space-y-2">
//         <div className="grid grid-cols-5 gap-2 text-center">
//           {reviewOptions.map((option: ReviewOption) => (
//             <p key={option.value} className="text-xs font-bold text-zinc-600">
//               {option.value}
//             </p>
//           ))}
//         </div>
//         <div className="grid grid-cols-1 gap-2">
//           {reviewOptions.map((option: ReviewOption) => (
//             <button
//               key={option.value}
//               type="button"
//               onClick={() => {
//                 if (note !== undefined) return;
//                 setNote(option.value);
//                 onReview(option.value);
//               }}
//               className={[
//                 "text-white px-2 py-2 w-full font-semibold text-sm rounded",
//                 note === option.value
//                   ? "bg-blue-800"
//                   : "bg-zinc-500 hover:bg-blue-600"
//               ].join(" ")}
//             >
//               {option.label}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }


export const ChatScaleReviewSection = ({ scaleLabels, onReview }: UserReviewButtonsProps) => {
  const [note, setNote] = React.useState<number | undefined>(undefined);

  const reviewOptions: ReviewOption[] = [
    { label: scaleLabels[0], value: 1, color: "bg-blue-400" },
    { label: scaleLabels[1], value: 2, color: "bg-blue-500" },
    { label: scaleLabels[2], value: 3, color: "bg-blue-600" },
    { label: scaleLabels[3], value: 4, color: "bg-blue-700" },
    { label: scaleLabels[4], value: 5, color: "bg-blue-800" },
  ];

  return (
    <div className="flex flex-col">
      {/* Desktop layout */}
      <div className="hidden sm:flex sm:space-x-1 justify-center items-center">
        <div className="max-w-lg w-full grid grid-cols-5 gap-1">
          {reviewOptions.map((option: ReviewOption) => (
            <div className="flex flex-col items-center gap-1" key={option.value}>
              <p
                className={[
                  "font-bold",
                  note === option.value ? "text-blue-800" : "text-zinc-600"
                ].join(" ")}
              >
                {option.value}
              </p>
              <button
                type="button"
                onClick={() => {
                  if (note !== undefined) return;
                  setNote(option.value);
                  onReview(option.value);
                }}
                className={[
                  "text-white px-2 py-1 w-full h-full font-semibold text-xs rounded",
                  note === option.value
                    ? "bg-blue-800"
                    : "bg-zinc-500 hover:bg-blue-600"
                ].join(" ")}
              >
                {option.label}
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Mobile layout */}
      <div className="sm:hidden space-y-2">
  {reviewOptions.map((option: ReviewOption) => (
    <div key={option.value} className="grid grid-cols-6 gap-2 items-center">
      <span className="font-bold text-zinc-600 text-left">{option.value}</span>
      <button
        type="button"
        onClick={() => {
          if (note !== undefined) return;
          setNote(option.value);
          onReview(option.value);
        }}
        className={[
          "col-span-5 text-white px-2 py-2 w-full font-semibold text-sm rounded flex items-center justify-center text-center",
          note === option.value
            ? "bg-blue-800"
            : "bg-zinc-500 hover:bg-blue-600"
        ].join(" ")}
      >
        {option.label}
      </button>
    </div>
  ))}
</div>

    </div>
  );
};
