import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import xss from 'xss';
import { badRequestResponses, FormResponses } from '../App.tsx';
import { MessageI, MessageItem } from './MessageItem.tsx';

export const ChatView = () => {
  const [form, setForm] = React.useState<FormResponses>({
    working_title: "",
    sector_of_activity: "",
    tasks: {
      generated: {},
      selected: {},
      total_selected: 0,
      total_generated: 0,
    },
    feedbacks: {
      matching_personal_tasks: 0,
      task_list_completeness: 0,
      comments: ""
    }
  });

  const [badRequestForm,setBadRequestForm] = React.useState<badRequestResponses>({
    working_title: "",
    sector_of_activity: "",
    reason: ""
  });

  const [formEnded, setFormEnded] = useState(false);
  const[badRequestFormEnded, setBadRequestFormEnded] = useState(false);

  const [messages, setMessages] = useState<MessageI[]>([
    {
      text: `Bienvenue dans l'univers des testeurs Mendo !  Cet outil nous permettra de mieux comprendre vos tâches métier pour vous adresser les Labs les plus utiles pour vous !`,
      sender: "system", type: "text"
    },
    {
      text: `Vos réponses sont anonymes et nous permettront de mieux étudier vos besoins métier`,
      sender: "system", type: "text"
    },
    {
      text: `Quel est votre métier ?`,
      sender: "system", type: "text"
    }
  ]);



  function sanitizeData<T extends Record<string, any>>(data: T): T {
    const sanitizedData = { ...data };

    for (const key in sanitizedData) {
      if (typeof sanitizedData[key] === 'string') {
        sanitizedData[key] = xss(sanitizedData[key]) as any;
      } else if (typeof sanitizedData[key] === 'object' && sanitizedData[key] !== null) {
        sanitizedData[key] = sanitizeData(sanitizedData[key]);
      }
    }

    return sanitizedData;
  }


  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      message: { value: string };
    };

    const userMessage = target.message.value;

    if (userMessage) {
      setMessages([...messages, { text: userMessage, sender: "user", type: "text" }]);
      target.message.value = '';

      console.log(messages);
      setBadRequestFormEnded(false);
      if (form.working_title && form.sector_of_activity) {
        // If the user has already provided their job title, ignore
        return;
      }
      if (form.working_title === "") {
        // If the user has provided their job title but not their sector of activity
        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/validation', sanitizeData({
          text: userMessage,
          topic : "specific professional role"
        }));
        if((response.data as MessageI).type === "text"){
          setMessages((prevMessages) => [
            ...prevMessages,
            response.data
          ]);
          setBadRequestForm(() => ({
            working_title: userMessage,
            sector_of_activity: "",
            reason: (response.data as MessageI).text
          }))
          setBadRequestFormEnded(true);
          return;
        }
        setForm((prevForm) => ({
          ...prevForm,
          working_title: userMessage
        }));
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: `Quel est votre secteur d'activité ?`,
            sender: "system", type: "text"
          }
        ]);
        return;
      }

      if (form.sector_of_activity === "") {
        // If the user has provided their job title but not their sector of activity
        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/validation', sanitizeData({
          text: userMessage,
          topic : "Sector of activity of a job"
        }));
        if((response.data as MessageI).type === "text"){
          setMessages((prevMessages) => [
            ...prevMessages,
            response.data
          ]);
          setBadRequestForm(() => ({
            working_title: form.working_title,
            sector_of_activity: userMessage,
            reason: (response.data as MessageI).text
          }))
          setBadRequestFormEnded(true);
          return;
        }
        setForm((prevForm) => ({
          ...prevForm,
          sector_of_activity: userMessage
        }));
      }

      try {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: "Mendo réfléchit...",
            sender: "system", type: "text"
          }
        ]);

        // @ts-ignore - Send the user's job title to the server
        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/messages', sanitizeData({
          text: form.working_title + " et " + form.sector_of_activity,
          sender: "user",
          type: "options"
        }));


        // Prompt the user to select the tasks that they perform in their job
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: "Voici une liste de tâches que vous pourriez réaliser dans votre métier et secteur d'activité.",
            sender: "system", type: "text"
          },

          {
            text: `Cochez celles qui correspondent à vos tâches quotidiennes.`,
            sender: "system", type: "text"
          },
          response.data,
        ]);

      } catch (error) {
        console.error('There was an error sending the message!', error);
      }
    }
  };

  // Logic to show the next message after the user has submitted the form
  useEffect(() => {
    if (form.working_title && form.tasks.total_selected > 0) {
      if (form.feedbacks.matching_personal_tasks === 0) {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            text: `Ces propositions correspondent-elles à vos tâches quotidiennes ?`,
            sender: "system", type: "commentary",
            scaleLabels: ["Pas d'accord", "Plutôt pas d'accord", "Neutre", "Plutôt d'accord", "D'accord"]
          }
        ]);
        return;
      }

      if (form.feedbacks.task_list_completeness === 0) {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            text: `Dans quelle mesure cette liste est-elle complète ?`,
            sender: "system", type: "commentary",
            scaleLabels: ["Très incomplète", "Incomplète", "Moyennement complète", "Plutôt complète", "Très complète"]
          }
        ]);
        return;
      }

      if (form.feedbacks.matching_personal_tasks > 0 && form.feedbacks.task_list_completeness > 0) {
        const lastMessage = messages[messages.length - 1];

        if (lastMessage.text.includes("Ma note est de")) {
          setMessages(prevMessages => [
            ...prevMessages,
            {
              text: `Avez-vous des remarques ou suggestions ? Écrivez votre retour dans le champ de message ci-dessous. Sinon, répondez "non" pour terminer.`,
              sender: "system", type: "text"
            }
          ]);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.feedbacks.matching_personal_tasks, form.feedbacks.task_list_completeness, form.working_title, form.tasks]);

  useEffect(() => {
    // Save the final comments to the form
    if (messages.length > 12 && form.feedbacks.matching_personal_tasks > 0 && form.feedbacks.task_list_completeness > 0) {
      const lastMessage = messages[messages.length - 1];

      if (lastMessage.sender === "user" && lastMessage.text.startsWith("Ma note est de") === false) {
        // If the last message was from the user, user sent a feedback message
        const lastMessageText = messages[messages.length - 1].text;

        setForm((prevForm) => ({
          ...prevForm,
          feedbacks: {
            ...prevForm.feedbacks,
            comments: lastMessageText
          }
        }));

        setMessages(prevMessages => [
          ...prevMessages,
          {
            text: `Merci ! Vos retours nous sont précieux.`,
            sender: "system", type: "text"
          },
          {
            text: `Vous pouvez maintenant fermer cette page.`,
            sender: "system", type: "text"
          }
        ]);

        setFormEnded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    if (formEnded) {
      // @ts-ignore - Save the form to the database
      axios.post(process.env.REACT_APP_API_URL + '/survey/save', sanitizeData(form))
        .then((response) => {
          console.log("Form saved successfully", response.data);
        })
        .catch((error) => {
          console.error("There was an error saving the form", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formEnded]);

  useEffect(() => {
    if (badRequestFormEnded) {
      // @ts-ignore - Save the form to the database
      axios.post(process.env.REACT_APP_API_URL + '/survey/badrequest-save', sanitizeData(badRequestForm))
      .then((response) => {
        console.log("Bad Request Form saved successfully", response.data);
      })
      .catch((error) => {
        console.error("There was an error saving the Bad Request form", error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badRequestFormEnded]);

  const isLastMessageFromSystem = messages[messages.length - 1].sender === "system";
  const isMessageSendingDisabled = !isLastMessageFromSystem || ["options", "commentary"].includes(messages[messages.length - 1].type) || formEnded;

  return (
    <div className="flex flex-col justify-between h-[90vh] w-full max-w-screen-lg border border-gray-300/60 rounded-lg overflow-hidden bg-white/50 shadow backdrop-blur-[10px]">
      <div className="flex flex-col w-full pt-4 pl-4 overflow-y-auto bg-transparent">
        {messages.map((msg, index) => {
          // Determine if this is the last message from the system before the next user message or end of array
          const isLastSystemMessage =
            msg.sender === "system" &&
            (index === messages.length - 1 || messages[index + 1].sender !== "system");

          return (
            <MessageItem
              feedbackId={form.feedbacks.matching_personal_tasks === 0 ? "matching_personal_tasks" : "task_list_completeness"}
              key={index}
              message={msg.text}
              sender={msg.sender}
              type={msg.type}
              scaleLabels={msg.scaleLabels}
              showAvatar={isLastSystemMessage}
              messageHistory={messages}
              formResponses={form}
              onSaveForm={(form: FormResponses) => setForm(form)}
              onSaveMessages={(messages: MessageI[]) => setMessages(messages)}
            />
          );
        })}

        <div ref={messagesEndRef} />
      </div>

      {!isMessageSendingDisabled && <form className="flex p-2.5 bg-transparent border-t border-gray-300/60" onSubmit={handleSendMessage}>
        <input
          type="text"
          name="message"
          placeholder={isLastMessageFromSystem ? "Écrire un message" : "Milo charge sa réponse..."}
          className={[
            'text-sm px-6 flex-1 py-2.5 border border-gray-300/60 rounded-full mr-2.5 bg-white/90',
          ].join(" ")}
          required
        />
        <button
          type="submit"
          className={[
            "text-sm p-2.5 px-5 border-none ease-in-out duration-300 text-white rounded-full cursor-pointer", "bg-blue-500/80 hover:bg-blue-500"
          ].join(" ")}
        >
          Envoyer
        </button>
      </form>}
    </div>
  );
};
