import React from 'react';
import { FormResponses, TaskList } from '../App.tsx';
// @ts-ignore
import profilePicture from '../assets/milo.png';
import { ChatCommentSection } from './chat/ChatCommentSection.tsx';
import { ChatOptionsSelection } from './chat/ChatOptionsSelection.tsx';
import { ChatTextMessage } from './chat/ChatTextMessage.tsx';


export interface GPTResponseContent {
  [category: string]: string[];
}

export interface MessageI {
  text: string;
  sender: "system" | "user";
  scaleLabels?: string[];
  type: "text" | "options" | "commentary";
}

interface MessageProps {
  feedbackId: keyof FormResponses["feedbacks"] | undefined;
  message: string;
  scaleLabels?: string[];
  sender: "system" | "user";
  type: "options" | "commentary" | "text";
  messageHistory: MessageI[];
  formResponses: FormResponses;
  showAvatar?: boolean;
  onSaveForm: (form: FormResponses) => void;
  onSaveMessages: (messages: MessageI[]) => void;
}



export const MessageItem = ({ feedbackId, message, sender, type, messageHistory, scaleLabels, formResponses, showAvatar, onSaveForm, onSaveMessages }: MessageProps) => {
  const handleScaleReview = (review: number) => {
    console.log("Reviewing", feedbackId, "with", review);

    const newValue = {
      ...formResponses,
      feedbacks: {
        ...formResponses.feedbacks,
        [feedbackId as string]: review
      }
    };

    onSaveForm(newValue);

    onSaveMessages([...messageHistory, { text: `Ma note est de ${review}`, sender: "user", type: "text" }]);

    console.log("Current form info", formResponses);
    console.log("New EDITED form info", newValue);
  }

  const handleOptionsSubmit = (selectedTasks: TaskList) => {
    const generatedTasks = JSON.parse(message) as TaskList;

    const totalGeneratedTasks = Object.values(generatedTasks).reduce((acc, taskList) => acc + taskList.length, 0);
    const totalSelectedTasks = Object.values(selectedTasks).reduce((acc, taskList) => acc + taskList.length, 0);

    console.log("Generated tasks", generatedTasks);
    console.log("Selected tasks", selectedTasks);
    console.log("Total selected tasks", totalSelectedTasks);

    // Save options to form
    onSaveForm({
      ...formResponses,
      tasks: {
        generated: generatedTasks,
        selected: selectedTasks,
        total_generated: totalGeneratedTasks,
        total_selected: totalSelectedTasks
      }
    });

    // Add a new message to the chat to confirm the selection
    onSaveMessages([...messageHistory, {  text: `J'ai choisi ${totalSelectedTasks} tâche${totalSelectedTasks === 1 ? '' : 's'}`, 
      sender: "user", type: "text" }]);
  }

  return (
    <div className='flex items-center gap-1 max-w-[85%]'>
      <img
        src={profilePicture}
        alt="AI Profile"
        className={[
          "w-10 h-10 mt-auto mb-2 rounded-full",
          (sender === "system" && showAvatar) ? "block" : "invisible"
        ].join(" ")}
      />

      <div
        className={[
          "flex items-center p-2 rounded-lg max-w-[60%] w-fit",
          sender === "user" ? "bg-[#dcf8c6] ml-auto" : "bg-white self-start m-2",
        ].join(" ")}
      >
        {type === "options" && <ChatOptionsSelection
          message={message}
          onSubmit={(tasks: TaskList) => handleOptionsSubmit(tasks)}
        />}
        {type === "commentary" && scaleLabels && <ChatCommentSection
          message={message}
          scaleLabels={scaleLabels}
          onReview={handleScaleReview}
        />}
        {type === "text" && <ChatTextMessage
          message={message}
        />}
      </div>

    </div>
  );
};
