import React from "react";
import { ChatScaleReviewSection } from "./ChatScaleReviewSection.tsx";

interface ChatCommentSectionProps {
  message: string;
  scaleLabels: string[];
  onReview: (note: number) => void;
}

export const ChatCommentSection = ({ message, scaleLabels, onReview }: ChatCommentSectionProps) => {
  return (
    <div className="flex flex-col space-y-4 sm:space-y-2 w-full">
      <div className='text-sm break-words'>{message}</div>
      <ChatScaleReviewSection scaleLabels={scaleLabels} onReview={onReview} />
    </div>
  );
}