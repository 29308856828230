import React from "react";

interface ChatTextMessageProps {
  message: string;
}

export const ChatTextMessage = ({ message }: ChatTextMessageProps) => {
  return (
    <div className="flex items-center justify-center px-1.5 text-sm break-words">{message}</div>
  );
}