import React, { useState } from "react";
import { TaskList } from "../../App";
import { GPTResponseContent } from "../MessageItem";

interface ChatOptionsSelectionProps {
  message: string;
  onSubmit: (tasks: TaskList) => void;
}

export const ChatOptionsSelection = ({ message, onSubmit }: ChatOptionsSelectionProps) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  if (!message) return null;

  const content = JSON.parse(message) as GPTResponseContent;

  const handleOptionChange = (category: string, option: string) => {
    setSelectedOptions(prevSelectedOptions => {
      const categoryOptions = prevSelectedOptions[category] || [];
      if (categoryOptions.includes(option)) {
        return {
          ...prevSelectedOptions,
          [category]: categoryOptions.filter(opt => opt !== option)
        };
      } else {
        return {
          ...prevSelectedOptions,
          [category]: [...categoryOptions, option]
        };
      }
    });
  };

  const handleSelectAllChange = (category, options, isChecked) => {
    if (isChecked) {
      // Select all options in the category
      setSelectedOptions(prevSelectedOptions => ({
        ...prevSelectedOptions,
        [category]: options
      }));
    } else {
      // Deselect all options in the category
      setSelectedOptions(prevSelectedOptions => ({
        ...prevSelectedOptions,
        [category]: []
      }));
    }
  };

  const handleSubmitForm = (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Submitting selected options', selectedOptions);
    // You can add code here to save the selected options to a server or local storage
    onSubmit(selectedOptions);
    setHasSubmitted(true);
  };

  return (
    <form onSubmit={handleSubmitForm} className='flex flex-col w-full p-2 text-sm break-words'>
      {Object.entries(content).map(([category, options]) => (
        <div key={category}>
          <label className="flex items-center gap-2 font-bold">
            <input
              type="checkbox"
              className="mt-0.5"
              disabled={hasSubmitted}
              onChange={(e) => handleSelectAllChange(category, options, e.target.checked)}
              checked={selectedOptions[category]?.length === options.length && options.length > 0}
            />
            {category}
          </label>
          {options.map(option => (
            <div key={option}>
              <label className="flex items-start gap-2 ml-6">
                <input
                  className="mt-1 checked:text-teal-500"
                  type="checkbox"
                  disabled={hasSubmitted}
                  name={category}
                  value={option}
                  checked={selectedOptions[category]?.includes(option) || false}
                  onChange={() => handleOptionChange(category, option)}
                />
                {option}
              </label>
            </div>
          ))}
          <br />
        </div>
      ))}
      {<button
        type="submit"
        className={[
          "px-4 py-2 font-bold text-white rounded",
          Object.values(selectedOptions).flat().length === 0
            ? "bg-gray-400"
            : "bg-blue-500 hover:bg-blue-700"
        ].join(" ")}
        disabled={Object.values(selectedOptions).flat().length === 0 || hasSubmitted}
      >
        Confirmer
      </button>}
    </form>

  );
}